import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faJs, faWordpress, faPhp } from '@fortawesome/free-brands-svg-icons';
import { faUser, faCode } from '@fortawesome/free-solid-svg-icons';

const Projects = () => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex flex-column" id="projects">
      <div className="my-auto">
        <h2 className="mb-5">Projects</h2>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0 crud"><FontAwesomeIcon icon={faCode} /> C.R.U.D. Applications</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                    <li>
                      <a href="projects/crud/movieflix_crud/index.php" target="_blank">Movie Flix </a><br />
                      <ul>
                        <li>This is a simple CRUD application (WIP) where the user can create, read, update and delete data.</li>
                        <li><strong>Technologies Used:</strong> HTML, CSS, JavaScript, PHP and MySQL</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0 vanilla-js"><FontAwesomeIcon icon={faJs} /> Vanilla JS</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                    <li><a href="projects/javascript/music-player/index.html" target="_blank">Music Player</a></li>
                    <li><a href="projects/javascript/memorygame/index.html" target="_blank">Memory Game</a></li>
                    <li><a href="projects/javascript/tictactoe/index.html" target="_blank">Tic Tac Toe</a></li>
                    <li><a href="projects/javascript/reaction/index.html" target="_blank">Reaction</a></li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0 react"><FontAwesomeIcon icon={faReact} /> React JS</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                    <li><a href="projects/react/abc/index.html" target="_blank">Learn ABC</a></li>
                    <li><a href="projects/react/showbox/index.html" target="_blank">Showbox</a></li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0 php"><FontAwesomeIcon icon={faPhp} /> PHP</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                  <li><a href="http://regalfunding.com/" target="_blank">Regal Funding of New York</a></li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0 wordpress"><FontAwesomeIcon icon={faWordpress} /> Wordpress</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                    <li><a href="https://kiddingaroundtoys.com/" target="_blank">Kidding Around Toys</a></li>
                    <li><a href="http://lackawannabusinesspark.com/" target="_blank">Lackawanna Business Park</a></li>
                    <li><a href="https://www.joannsomersmd.com/" target="_blank">Joann Somers, M.D.</a></li>
                    <li><a href="http://www.1st2ndmortgage.com/" target="_blank">1st2ndMortgage of New Jersey</a></li>
                    <li><a href="http://timherricklawfirm.com/" target="_blank">Tim Herrick Law</a></li>
                    <li><a href="http://www.rikersonline.com/" target="_blank">Rikers Online</a></li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0 other"><FontAwesomeIcon icon={faUser} /> Other Client Websites using CSS, HTML and JavaScript/jQuery</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                    <li><a href="http://orthospinerehabpc.com/" target="_blank">Orthospine Rehab PC</a></li>
                    <li><a href="http://marieferrerpt.com/" target="_blank">Marie Ferrer PT</a></li>   
                    <li><a href="http://jacknassmd.com/" target="_blank">Jack Nass MD</a></li>      
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="projects-item mb-5">
          <div className="projects-content">
            <h3 className="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="44" viewBox="0 0 40 44"><path d="M11.8 28.4l-1.6.1v.7c0 1.9 1.1 2.3 2 2.3h.2l2.6-.2c.1-.3.1-.5.1-.5s-.3-16.3-.4-18.3c0-.5-.1-.8-.3-1.1-.9-.1-1.8-.1-2.4-.2h-.3c-.6 0-1.1.2-1.4.5-.5.5-.4 1.2-.4 1.2v1.2c1.3.1 2.2.1 2.2.1s1.4.3 1.5 1.8c.1 1.6.1 10.6.1 10.6s.1 1.5-1.9 1.8zm-5 .4l-2.5.2s-2.1.3-2.2-2c0-1.7-.2-11.5-.2-11.5s-.1-1.8 2.1-1.7c.7 0 1.6.1 2.7.2 0-1.6-.1-3-.1-4.1-1.5-.2-2.8-.3-3.7-.4-2.9-.3-2.8 2.1-2.8 2.1s.3 17.9.4 20.1c.1 2.6 2.9 2.2 2.9 2.2l3.5-.4c0-1.2 0-2.9-.1-4.7zm31.1-22.3c-.1-3.4-3.1-4.1-3.1-4.1s-10.2-1.5-14.7-2.1c-4.5-.6-4.2 3.8-4.2 3.8s1 31.7 1.1 35.6c.1 4.7 4.5 3.9 4.5 3.9l15.4-2.6c2.8-.6 2.9-4.1 2.9-4.1s-1.8-26.7-1.9-30.4zm-3.4 26.9l-11.5 1.2s-3.3.5-3.4-3.6c-.1-3-.7-20.1-.7-20.1s-.2-3.1 3.2-2.9c3.5.3 11.9 1 11.9 1s2.2.5 2.3 3.1c.1 2.8 1.1 18.3 1.1 18.3s.2 2.5-2.9 3zm-11.6.6l4.4-.5c-.2-4.5-1-22-1.1-24.5-1.6-.1-3.1-.2-4.2-.3h-.3c-.7 0-1.3.2-1.7.6-.6.6-.6 1.7-.6 1.7v.3c1.8.2 3.2.3 3.2.3s1.9.4 2 2.5c.1 2.3.6 14.7.6 14.7s.2 2.1-2.5 2.5l-2.6.2c.2 2.1 1.4 2.6 2.4 2.6.3-.1.4-.1.4-.1zm-6.7-2.2l-3.6.3s-2.9.4-2.9-2.9c0-2.4-.3-16.3-.3-16.3s-.2-2.6 2.8-2.4c.9.1 2.1.2 3.4.3-.1-2.6-.2-4.7-.2-5.8-1.7-.2-3.4-.3-4.6-.5-3.9-.4-3.7 3-3.7 3s.4 25.5.5 28.6c.1 3.7 3.9 3.1 3.9 3.1l4.9-.6c-.1-1.4-.1-3.8-.2-6.8z" fill="#343a40"></path></svg> Phone Gap</h3>
            <div className="subheading mb-3"></div>
            <p></p>
            <div className="container">
              <div className="row no-gutters">
                <div className="col-lg-6 col-xs-12">
                  <ul>
                    <li><a href="http://www.santinovalenzuela.com/projects/learnfrench/index.html" target="_blank">Learn French App</a>
                      {/* <ul>
                        <li>Link</li>
                      </ul> */}
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xs-12 other">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  )
}

export default Projects;