import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faGithub, faInstagram, faCodepen } from '@fortawesome/free-brands-svg-icons';

const About = (props) => {
  return (
    <section className="resume-section p-3 p-lg-5 d-flex d-column" id="about">
      <div className="my-auto">
        <h1 className="mb-0">Santino
          <span className="text-primary"> Valenzuela</span>
        </h1>
        <div className="subheading mb-5">
          (201) 844-3388 · 
          <a href="mailto:s0nnyv123@gmail.com">s0nnyv123@gmail.com</a>
          <br />
          <a href="SantinoV-Resume.pdf" target="_blank">Resume</a>
        </div>
        <p className="lead mb-5">Hi! My name is Santino.  I create beautiful and functional websites for small businesses.  <br />Below are a few services i provide:</p>
        <div className="social-icons">
          <a href="https://github.com/valenzsa" target="_blank">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href="https://codepen.io/s0nnyv123" target="_blank">
            <FontAwesomeIcon icon={faCodepen} />
          </a>
          <a href="https://www.linkedin.com/in/santino-valenzuela" target="_blank">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
          <a href="https://twitter.com/valenzsa_" target="_blank">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a href="https://www.instagram.com/valenzsa_" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </section>
  )
}

export default About;