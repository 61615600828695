import React, { Component, Fragment } from 'react';
import Navigation from './components/Navigation/Navigation';
import About from './components/About/About';
import Projects from './components/Projects/Projects';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Navigation />
        <div className="container-fluid p-0 bg-image">
          <About />
          <hr className="m-0" />
          <Projects />
          <hr className="m-0" />
        </div>
      </Fragment>
    );
  }
}

export default App;
